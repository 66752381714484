import {
    createRouter,
    createWebHistory,
    //createWebHashHistory
} from 'vue-router'

import HomePage from '../views/HomePage'
import SafetyPage from '../views/SafetyPage'
import ContactUsPage from '../views/ContactUsPage'
import BookPage from '../views/BookPage'
import WherePage from '../views/WherePage'
import TermsPage from '../views/TermsPage'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/safety',
            component: SafetyPage
        },
        {
            path: '/contacts',
            component: ContactUsPage
        },
        {
            path: '/where',
            component: WherePage
        },
        {
            path: '/book',
            component: BookPage
        },
        {
            path: '/terms',
            component: TermsPage
        },
        {
            path: "/:catchAll(.*)",
            component: HomePage
        },
    ]
})

export default router