<template>
    <div class="container-fluid m-0 mb-5 p-0">
        <div class="row">
            <div class="col">
                <div class="card">

                    <div class="card-body">
                        <h5 class="card-title text-center "> {{ name }}</h5>
                        <p v-html="description" class="card-text"></p>
                    </div>

                    <div class="card-footer">
                        <small>

                            <h6 v-if="!special"> Price: {{ price * quantity }}€</h6>
                            <h6 v-else> Price: {{ specialPrice }}</h6>
                            <div class="container-fluid m-0 p-0">
                                <div class="row mt-2 m-0 p-0">
                                    <div class="col-sm-4 col-4 d-flex align-items-center justify-content-start p-0 m-0">
                                        <button class="btn btn-dark increment-buttons"
                                            @click="removeExtraCard"
                                            :disabled="quantity === 0">-</button>
                                    </div>
                                    <div class="col-sm-4  col-4 d-flex align-items-center justify-content-center p-0 m-0">
                                        <h4 class="">{{ quantity }}</h4>
                                    </div>
                                    <div class="col-sm-4  col-4 d-flex align-items-center justify-content-end p-0 m-0">
                                        <button :disabled="quantity>=7" class="btn btn-dark increment-buttons" 
                                            @click="() => { if(this.quantity<=7){ this.quantity += 1; this.emitAddToCart(); }}">+</button>
                                    </div>
                                </div>

                                <div class="row mt-2 mb-2 justify-content-center">
                                    <!--
                                    <div class="col-6 col">
                                        <button @click="emitAddToCart"
                                            class="btn pir-success w-100  ">Add</button>
                                    </div>
                                    -->
                                    <div v-if="bookedAmount > 0" class="col-8">
                                        <div @click="emitRemoveFromExtra" class="btn pir-danger w-100">Remove all</div>
                                    </div>
                                </div>
                                
                                <div v-if="bookedAmount > 0" class="row mt-1 rounded">
                                    <div class="col mb-2 text-center">
                                        <span class="" style="font-weight: 600;">{{ name }} for {{ bookedAmount }} {{bookedAmount==1?'person': 'people' }} in
                                            cart</span>
                                    </div>
                                </div>
                            </div>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        name: String,
        description: String,
        duration: String,
        price: Number,
        selected_service: Boolean, // indicates if a service has been selected (only useful for extra)
        cart: Array,
        special: Boolean

    },
    data() {
        return {
            quantity: 0,
            bookedAmount: 0,
        }
    },
    mounted() {
        let v = []
        if (this.cart.length !== 0) v = this.cart.filter(x => x.name_service === this.name);
        if (v.length !== 0){
            this.bookedAmount = v[0].quantity;
            this.quantity = this.bookedAmount;
        }
    },
    computed: {
        specialPrice() {
            if (this.quantity === 0) return "Select an amount first";
            if (this.quantity <= 2) return "100€";
            let total = 100;
            for (let i = 2; i < this.quantity; i++) {
                if ((1 + i) % 4 == 0) total += 100;
                else total += 30;
            }
            return total+"€";
        }
    },
    methods: {
        removeExtraCard(){
            if (this.quantity > 0){
                this.quantity -= 1;
                this.emitAddToCart();
            }
            if (this.quantity === 0){
                this.emitRemoveFromExtra();
            }
        },

        emitAddToCart() {
            if (this.quantity > 0)
                this.$emit('serviceAddedToCart', { "name_service": this.name, "quantity": this.quantity, "price": this.price, "id": this.id });
            this.bookedAmount = this.quantity;
        },
        emitRemoveFromExtra() {
            this.$emit('removeFromExtras', { "name_service": this.name });
            this.bookedAmount = 0;
            this.quantity = 0;
        },

    }
}
</script>

<style scoped>
</style>
