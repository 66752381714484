<template>
  <!--- -->
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center bg-para">
    <div class="row mt-4 mb-4 w-100">
      <div class="col text-center clickable offset">
        <h5 style="font-weight: 250; font-style: italic; font-size: xx-large; font-weight: 700;" 
        @click="()=>{this.$emit('emitPageChange',{page:'book'}); }"
        
        > Are you ready to fly? </h5>

        <h6 @click="()=>{this.$emit('emitPageChange',{page:'book'}); }" >Click <u>here</u> to start the adventure</h6>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-para {
  background-image: url('../assets/main_background.png');/**/
  background-size: cover;
  background-position: center;
  height: 100%;
  font-style: oblique;
}

.offset{
  margin-bottom: 200px;
  color: black;
}
</style>
