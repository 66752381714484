import axios from "axios";

const instance = axios.create({
    //baseURL: "http://127.0.0.1:5000/api",
    baseURL: "https://www.paraglidingrome.com/api",
    timeout: 1000 * 10,
});

// Interceptor for outbound requests
/*
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config
    },

    (error) => {
        return Promise.reject(error);
    }
)
*/

export default instance;
