<template>
    <div class="container-fluid p-0 m-0  d-flex flex-column justify-content-center align-items-center vh-100">
      <div class="row text-center ">
        <div class="col">
          <h2>Your booking request is on its way!</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3>You'll receive an email very soon with all the details you need to know!</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          You will be automatically redirected to the homepage in {{ val }}
        </div>
      </div>
    </div>
  </template>
  
<script>
export default {
  data() {
    return {
      val: 10,
    };
  },
  mounted(){
    this.countdown(10);
  },
  methods:{
    countdown(seconds) {
    let timer = setInterval(() => {
      if (seconds > 1) {
        seconds--;
        this.val--;
      } else {
        clearInterval(timer);
        this.$emit("emitPageChange",{page:'home'});
      }
    }, 1000);
  },
  }
}
</script>
  
<style scoped>
</style>
  