<template>
  <div class="container-fluid  ">
    <form @submit.prevent="submitForm" novalidate>

      <div class="form-group row justify-content-center ">
        <div class="col-lg-6">
          <label class="active" for="dateStandard">Desidered day for the experience:</label>
          <input class="form-control" type="date" id="dateStandard" name="dateStandard" v-model="formData.date" required>
          <span v-if="formErrors.date" class="text-danger">{{ formErrors.date }}</span>
          <a v-if="show_contact" class="text-danger clickable" @click="()=>{ this.$emit('emitPageChange',{page:'contacts'})}" >here!</a>

        </div>
      </div>

      <div class="form-group row justify-content-center mt-3">
        <div class="col-lg-6">
          <label for="name">Name:</label>
          <input type="text" class="form-control" id="name" v-model="formData.name" required>
          <div v-if="formErrors.name" class="text-danger">{{ formErrors.name }}</div>
        </div>
      </div>

      <div class="form-group row justify-content-center mt-3">
        <div class="col-lg-6">
          <label for="surname">Surname:</label>
          <input type="text" class="form-control" id="surname" v-model="formData.surname" required>
          <div v-if="formErrors.surname" class="text-danger">{{ formErrors.surname }}</div>
        </div>
      </div>

      <div class="form-group row justify-content-center">
        <div class="col-sm-12 col-md-3 col-lg-2 mt-3">
          <label for="phonePrefix">Phone Prefix:</label>
          <input type="text" class="form-control" id="phonePrefix" v-model="formData.phonePrefix" required>
          <div v-if="formErrors.phonePrefix" class="text-danger">{{ formErrors.phonePrefix }}</div>
        </div>

        <div class="col-sm-12 col-md-9 col-lg-4 mt-3">
          <label for="cellphone">Cellphone Number:</label>
          <input type="text" class="form-control" id="cellphone" v-model="formData.cellphone" required>
          <div v-if="formErrors.cellphone" class="text-danger">{{ formErrors.cellphone }}</div>
        </div>

      </div>

      <div class="form-group row justify-content-center mt-3">
        <div class="col-lg-6">
          <label for="email">Email:</label>
          <input type="email" class="form-control" id="email" v-model="formData.email" required>
          <div v-if="formErrors.email" class="text-danger">{{ formErrors.email }}</div>
        </div>
      </div>

      <div class="form-group form-check row justify-content-center mt-3">
        <div class="col">
          <div class="container-fluid p-0 m-0">
            <div class="row">
              <div class="col justify-content-center d-flex">
                <input type="checkbox" class="form-check-input" id="agree" v-model="formData.agree" required>
                <label class="form-check-label ms-1" for="agree"> I agree to the <a 
                  @click="()=> this.$router.push('/terms') " class="link-secondary clickable">Terms of service</a></label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 justify-content-center d-flex">
                <div v-if="formErrors.agree" class="text-danger">{{ formErrors.agree }}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="col justify-content-center d-flex mt-2">
          <button type="submit" class="btn btn-outline-light">Submit</button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: '',
        surname: '',
        phonePrefix: '+',
        cellphone: '',
        email: '',
        date: '',
        agree: false
      },
      formErrors: {},
      show_contact: false
    };
  },
  beforeMount(){
    window.addEventListener("beforeunload", this.saveForLater)
  },

  mounted(){
    let data = localStorage.getItem('book_data');
    if(data){
      data = JSON.parse(data);
      this.formData.name = data.name;
      this.formData.surname = data.surname;
      this.formData.phonePrefix = data.phonePrefix;
      this.formData.cellphone = data.cellphone;
      this.formData.email = data.email;
      this.formData.date = data.date;
      this.formData.agree = data.agree;
    }
  },

  beforeUnmount(){
      window.removeEventListener("beforeunload", this.saveForLater)
      this.saveForLater();
    },  

  methods: {
    saveForLater(){
      let data = {}
      data.name = this.formData.name ;
      data.surname = this.formData.surname;
      data.phonePrefix = this.formData.phonePrefix;
      data.cellphone = this.formData.cellphone;
      data.email = this.formData.email;
      data.date = this.formData.date;
      data.agree = this.formData.agree;

      localStorage.setItem('book_data',JSON.stringify(data));
    },

    //goToTerms(){ this.$emit('emitPageChange',{page:'terms'}); },

    async submitForm() {
      // Validate form fields
      this.formErrors = {}; 
      this.formData.name = this.formData.name.trim();
      this.formData.surname = this.formData.surname.trim();
      this.formData.phonePrefix = this.formData.phonePrefix.trim();
      this.formData.cellphone = this.formData.cellphone.trim();
      this.formData.email = this.formData.email.trim();

      if (!this.formData.name) {
        this.formErrors.name = 'Please enter your name';
      } else if (!/^[a-zA-Z]+$/.test(this.formData.name)) {
        this.formErrors.name = 'Name should only contain letters';
      }

      if (!this.formData.surname) {
        this.formErrors.surname = 'Please enter your surname';
      } else if (!/^[a-zA-Z]+$/.test(this.formData.surname)) {
        this.formErrors.surname = 'Surname should only contain letters';
      }

      if (!this.formData.phonePrefix) {
        this.formErrors.phonePrefix = 'Please enter your phone prefix';
      } else if (!/^\+[\d]+$/.test(this.formData.phonePrefix)) {
        if (this.formData.phonePrefix.charAt(0) !== '+')
          this.formErrors.phonePrefix = 'Phone prefix should start with +';
        else
          this.formErrors.phonePrefix = 'Phone prefix should only contain \'+\' followed by numbers';
      }

      if (!this.formData.cellphone) {
        this.formErrors.cellphone = 'Please enter your cellphone number';
      } else if (!/^\d+$/.test(this.formData.cellphone)) {
        this.formErrors.cellphone = 'Cellphone number should only contain numbers';
      }

      if (!this.formData.email) {
        this.formErrors.email = 'Please enter your email';
      } else if (!this.isValidEmail(this.formData.email)) {
        this.formErrors.email = 'Please enter a valid email';
      }

      if(!this.formData.date){
        this.formErrors.date = "Please insert a day for the experience";
      } else {
        let curr = new Date()
        let s_date = new Date(this.formData.date)
        if (curr>s_date){
          this.formErrors.date = "Please insert a valid day";
        }
        if ( curr.getFullYear()==s_date.getFullYear()&&curr.getMonth()==s_date.getMonth()&&curr.getDate()==s_date.getDate()){
          this.formErrors.date = "For same-day experiences, kindly contact us directly through phone ";
          this.show_contact = true;
        }
      }

      if (!this.formData.agree) {
        this.formErrors.agree = 'Please agree to the above statements';
      }

      // If there are no errors, proceed with form submission
      if (Object.keys(this.formErrors).length === 0) {
        let s_data = new Date(this.formData.date)
        //console.log('Form submitted successfully');
        this.$emit("booked", {
            'name': this.formData.name,
            'surname': this.formData.surname,
            'phone_prefix': this.formData.phonePrefix,
            'phone_number': this.formData.cellphone,
            'email': this.formData.email,
            'date': s_data.getFullYear().toString()+"-"+
                    (s_data.getMonth() + 1).toString().padStart(2, '0')+"-"+
                    s_data.getDate().toString().padStart(2, '0'),
            'agreed_terms': this.formData.agree
          });
      }
    },
    isValidEmail(email) {
      // Email validation logic
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }
};
</script>

<style scoped>
.form-check-input:checked{
  background-color: black;
  border-color: whitesmoke;
}
</style>
