<template>
  <div class="containter-fluid p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col m-0 p-0">
        <nav class="navbar navbar-expand-md navbar-dark rounded-bottom">
          <div class="container-fluid">
            <span class="navbar-brand">Paragliding Rome</span>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar_elements"
              aria-controls="navbar_elements" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbar_elements">
              <ul class="navbar-nav me-auto mt-1">
                <li class="nav-item d-flex justify-content-center">
                  <a :class="'nav-link '+isActive(0)" aria-current="page" @click="goToHome" data-bs-toggle="collapse" data-bs-target="#navbar_elements">Home</a>
                </li>
                <li class="nav-item d-flex justify-content-center">
                  <a :class="'nav-link '+isActive(1)" @click="goToSafety" data-bs-toggle="collapse" data-bs-target="#navbar_elements">Safety</a>
                </li>
                <li class="nav-item d-flex justify-content-center">
                  <a :class="'nav-link '+isActive(2)" @click="goToWhere" data-bs-toggle="collapse" data-bs-target="#navbar_elements">Places</a>
                </li>
                <li class="nav-item d-flex justify-content-center">
                  <a :class="'nav-link '+isActive(3)" @click="goToContacts" data-bs-toggle="collapse" data-bs-target="#navbar_elements">Contacts</a>
                </li>
                <li class="nav-item d-flex justify-content-center">
                  <a :class="'nav-link '+isActive(4)" @click="goToBook" data-bs-toggle="collapse" data-bs-target="#navbar_elements">Book experience</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
  
<script>
export default{

  props:{
    page: Number,
  },

  computed: {
    isActive() { return (section) => (this.page === section ? 'active' : '');},
  },

   methods: {
    goToHome() { this.$emit("emitPageChange",{page:'home'});},
    goToSafety() { this.$emit("emitPageChange",{page:'safety'}); },
    goToWhere() { this.$emit("emitPageChange",{page:'where'});},
    goToContacts() { this.$emit("emitPageChange",{page:'contacts'}); },
    goToBook() { this.$emit("emitPageChange",{page:'book'});},  
  }
}
</script>
  
<style scoped>
.nav-item:hover{
  cursor: pointer;
}
.navbar{
  position: absolute;
  width: 100%;
  z-index: 999;
}

@media (max-width: 767px) {
  .navbar-dark {
    /*background: rgb(67,69,77);
    background: radial-gradient(circle, rgba(67,69,77,1) 0%, rgba(179,6,200,1) 100%);*/
    /*
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 22%, rgba(179,6,200,1) 100%);*/

    background: rgb(109,39,228);
    background: radial-gradient(circle, rgba(109,39,228,1) 22%, rgba(179,6,200,1) 100%);
  };
}
</style>
  