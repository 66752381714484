<template>
  <!--- -->
  <div class="container d-flex flex-column vh-100 align-items-center justify-content-center">
    
    <div class="row ">
      <div class="col text-center">
        <h1 class="pir-color">Contact us</h1>
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <span>For any questions or same-day bookings, feel free to reach out using the provided contact options. 
          <br>Opting for a direct phone call allows us to assist you promptly and with ease!
        </span>
      </div>
    </div>

    <div class="row mt-4 text-center">
        <div class="col social pe-2">
            <a href="tel:3805991142"><i class="fa fa-phone telephone"></i></a>
        </div>
        <h4 class="d-inline telephone">Phone call</h4>
    </div>

    <div class="row mt-2 text-center">
        <div class="col social pe-2">
            <a href="https://wa.me/3805991142"><i class="fa fa-whatsapp whatsapp"></i></a>
        </div>
        <h4 class="whatsapp">Whatsapp</h4>
    </div>

    <div class="row mt-2 text-center">
        <div class="col social pe-2">
          <a href="https://telegram.me/flyingmp"><i class="fa fa-telegram telegram"></i></a>
        </div>
        <h4 class="telegram">Telegram</h4>
    </div>

    <div class="row mt-2 text-center">
        <div class="col social pe-2">
          <a href="mailto:paraglidingrome@gmail.com?subject=Booking%20for%20YYYY/MM/DD&body=Hello I'm John Doe, my phone is +12 3456789. and I'd like to book..."
          ><i class="fa fa-envelope email"></i></a>
        </div>
        <h4 class="email">Mail</h4>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AboutUsPage',
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>
