<template>
  <div class="container justify-content-center mt-5 pt-4">
    
    <div class="row">
      <div class="col text-center">
        <h1 class="pir-color">Where to fly</h1>
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        In central Italy we have multiple interesting places where we can fly.<br> 
        Our pilots will decide which will be better depending on weather forecasts for your experience
      </div>
    </div>

    <div v-for="(place,i) in places" :key="i">
      <div class="row mb-4 w-100 m-0 p-0">
        <div class="col m-0 p-0">
          <PlaceCard
          :name="place.name"
          :description="place.description"
          :index="i"
          />
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
export default {
  data(){
    return {
      places : [],
    }
  },
  async mounted(){
    let resp = await this.$axios.get("/places");
    //console.log(resp);
    this.places = resp.data.content
  },
}
</script>

<style scoped>

</style>
