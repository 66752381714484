<template>
  <div class="container justify-content-center mt-5 pt-4">
    
    <div class="row">
      <div class="col text-center">
        <h1 class="pir-color">Terms of service</h1>
      </div>
    </div>

    <div class="row mb-5">
      <div v-html="terms" class="col text-center">
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data(){
    return{
      terms : '',
    }
  },
  async mounted(){
    let _ = await this.$axios.get("/terms");
    this.terms = _.data.content;
  }
}
</script>

<style scoped>

</style>
