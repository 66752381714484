<template>
    <div class="container-fluid justify-content-center align-items-center">
        <div class="row mt-5">
            <div class="col text-center">
                <h1>{{ name }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col"> <!---10 col-sm-5-->
                <img class="img-fluid rounded" :src="getImg(this.name)">
            </div>
            <!--<div v-html="description" class="mt-2 col-5 col-sm-7"></div>-->
            
        </div>
    </div>
</template>

<script>
import images from "../assets/img";

export default {
    props: {
        name: String,
        description: String,
        index: Number
    },
    methods: {
        getImg(name) { return images[name]; },
    },
}
</script>