<template>
  <div class="container-fluid mt-5 pt-2">
    <div  v-if="currentStep!==3" class="row mt-4">
      <div class="col text-center">
        <h1 class="pir-color"> Book your experience now and pay later!</h1>
      </div>
    </div>

    <div v-if="currentStep==0" class="row mb-4 ">
      <div class="col text-center">
        We offer different kind of flights, from relaxed to adrenaline driven ones!<br>
        <span style="font-style:italic;"><u>You'll be able to modify the cart in the summary later</u></span>
      </div>
    </div>

    <div v-if="!isMobile && currentStep==0" class="row  row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-8 g-4 ">
      <div class="col" v-for="exp in json.experiences" :key=exp.title>
        <ExperienceCard @addedToCart="handleAddToCart" @removedFromCart="handleRemoveExperience"
          :id="exp.id"  
          :name="exp.title" 
          :duration="exp.duration" 
          :price="exp.price" 
          :description="exp.description"  
          :cart="cart"
          :discount="this.discountValue"
          />
      </div>
    </div>
    <div v-if="isMobile && currentStep==0" class="row">
      <div class="col">
        <div class="container-fluid p-0 m-0">
          <div class="row">

            <i 
            @click="scrollMobileExperience(-1);" class="col-1 p-0 d-flex align-items-center justify-content-center ps-2 fa fa-chevron-left" ></i>

            <div  class="col-10">
              <div class="container-fluid m-0 p-0">
                <div v-for="(exp,i) in json.experiences" :key=i  class="row m-0 p-0">
                    <ExperienceCard @addedToCart="handleAddToCart" @removedFromCart="handleRemoveExperience"
                      :id="exp.id"  
                      :name="exp.title" 
                      :duration="exp.duration" 
                      :price="exp.price" 
                      :description="exp.description"  
                      :cart="cart"
                      :discount="this.discountValue"
                      :hidden="i!==mobileExperienceIndex"
                    />
                </div>
              </div>
  
            </div>
            <i 
            @click="scrollMobileExperience(+1);" class="col-1 p-0 d-flex align-items-center justify-content-center pe-2 fa fa-chevron-right" ></i>
          </div>

        </div>
      </div>
    </div>

    <div v-if="currentStep==1" class="row mt-5 mb-2">
      <div class="col text-center">
        <h2> Services </h2>
      </div>
    </div>
    <div v-if="!isMobile && currentStep==1" class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-8 g-4 justify-content-center">
      <div class="col" v-for="service in json.services" :key=service.title>
        <ExtraService @serviceAddedToCart="handleExtraAddToCart" @removeFromExtras="handleRemoveExtra"
          :id="service.id"  
          :name="service.title" 
          :price="service.price"
          :description="service.description" 
          :selected_service="cart.length > 0" 
          :cart="extras" 
          :special="true"
          />
      </div>
    </div>
    <div v-if="isMobile && currentStep==1" class="row">
      <div class="col">
        <div class="container-fluid p-0 m-0">
          <div class="row">

            <i
            @click="scrollMobileExperience(-1);" class="col-1 p-0 d-flex align-items-center justify-content-center ps-2 fa fa-chevron-left"></i>
            <div class="col-10">
              <div class="container-fluid m-0 p-0">
                <div  v-for="(service,j) in json.services" :key=j  class="row m-0 p-0">
                  <ExtraService @serviceAddedToCart="handleExtraAddToCart" @removeFromExtras="handleRemoveExtra"
                    :id="service.id"   
                    :name="service.title" 
                    :price="service.price"
                    :description="service.description" 
                    :selected_service="cart.length > 0"
                    :cart="extras" 
                    :special="true"
                    :hidden="j!==mobileServiceIndex"
                    />
                </div>
              </div>
              
            </div>
            <i 
            @click="scrollMobileExperience(+1);" class="col-1 p-0 d-flex align-items-center justify-content-center pe-2 fa fa-chevron-right"></i>
            </div>
        </div>
      </div>
    </div>

    <div v-if="cart.length > 0 && currentStep==2" class="row mt-5">
      <div class="col">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col">
              <h2 class="text-center pir-color">Booking Summary</h2>
            </div>
          </div>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col p-0">
                <BookingCart 
                :cart="this.cart" 
                :extra="this.extras" 
                :discount="this.discountValue"
                @emitRemoveItem="handleRemoveItemFromCart"
                @discountApplied="updateDiscount" />
              </div>
            </div>
          </div>

          <div class="container-fluid mt-3">
            <BookingForm
            @booked="succesfullBooking"
            @emitPageChange="(payload)=> { this.$emit('emitPageChange',payload)}"
            />
          </div>

        </div>
      </div>
    </div>

    <div v-if="currentStep==3" class="row">
      <div class="col">
        <SuccBooking
        @emitPageChange="(payload)=> { this.currentStep=0 ; return this.$emit('emitPageChange',payload)}"
        />
      </div>
    </div>

    <div v-if="currentStep!==3" class="row extra-space"></div>

    <div v-if="currentStep!==3" class="float-buttons">
      <button v-if="currentStep>0" class="btn pir-btn me-2" @click="switchPage(-1)">Previous</button>
      <button v-if="currentStep<1 || (currentStep==1 && cart.length>0)" class="btn pir-btn" 
        @click="switchPage(+1)" :disabled="cart.length==0"
        >Next</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "BookPage",
  data() {
    return {
      json: {"experiences":[], "services":[]},
      isMobile: false,
      mobileExperienceIndex: 0,
      mobileServiceIndex: 0,
      cart: [],
      extras: [],
      currentStep: 0,

      discountValue:0,
      discountApplied:""
    };
  },
  
  methods: {
    handleAddToCart(payload) {
      let duplicate = false;
      if (this.cart.length === 0) {
        this.cart.push(payload);
        return;
      }
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].name_exp === payload.name_exp) {
          this.cart[i] = payload;
          duplicate = true;
        }
      }
      if (!duplicate) {
        this.cart.push(payload);
      }
    },
    handleExtraAddToCart(payload) {
      let duplicate = false;
      if (this.extras.length === 0) {
        this.extras.push(payload);
        return;
      }
      for (let i = 0; i < this.extras.length; i++) {
        if (this.extras[i].name_service === payload.name_service) {
          this.extras[i] = payload;
          duplicate = true;
        }
      }
      if (!duplicate) {
        this.extras.push(payload);
      }
    },
    handleRemoveExperience(payload){
      this.cart = this.cart.filter(el => el.name_exp !== payload.name_exp);
    },
    handleRemoveExtra(payload){
      this.extras = this.extras.filter(el => el.name_service !== payload.name_service);
    },

    handleRemoveItemFromCart(payload) {
      let type_item = payload.type_item;
      let name_item = payload.name_item;
      if (type_item === "EXTRA") {
        this.extras = this.extras.filter(el => el.name_service !== name_item);
      }
      else if (type_item === "SERVICE") {
        this.cart = this.cart.filter(el => el.name_exp !== name_item);
        if (this.cart.length==0) this.currentStep = 0;
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    scrollMobileExperience(amount) {
      let exp_len = this.json.experiences.length;
      if (this.mobileExperienceIndex + amount < 0)
        this.mobileExperienceIndex = exp_len - 1;
      else if (this.mobileExperienceIndex + amount >= exp_len)
        this.mobileExperienceIndex = 0;
      else
        this.mobileExperienceIndex += amount;
    },
    scrollMobileService(amount) {
      let exp_len = this.json.services.length;
      if (this.mobileServiceIndex + amount < 0)
        this.mobileServiceIndex = exp_len - 1;
      else if (this.mobileServiceIndex + amount >= exp_len)
        this.mobileServiceIndex = 0;
      else
        this.mobileServiceIndex += amount;
    },
    switchPage(amount){
      let limit = 2;
      if (this.currentStep + amount >= 0 || this.currentStep+amount<=limit)
        this.currentStep += amount;
    },
    updateDiscount(discountCode){
      this.discountApplied = discountCode;
    },
    async succesfullBooking(info_user){
      this.currentStep=3; 
      try{
        await this.$axios.post("/booking",
        {info_user,experiences: this.cart, services:this.extras, discount_code: this.discountApplied })
        localStorage.removeItem('book_data');
        localStorage.removeItem('cart');
        localStorage.removeItem('extras');
        localStorage.removeItem('currentStep');
        this.cart=[];
        this.extras=[];
      }catch(e){
        console.log("error while booking (code sfb): ", e.toString())
        return
      }
    },
    saveForLater(){
      localStorage.setItem('cart', JSON.stringify({'cart':this.cart}));
      localStorage.setItem('extras', JSON.stringify({'extras':this.extras}));
      localStorage.setItem('currentStep', this.currentStep);
    },
    arrowShower(generalCart){
      //console.log("QUANTE VOLTE?")
      if (generalCart.length>1) return 'visibility: visible;'
      return 'visibility: hidden;'
    }
  },

  async beforeMount(){
    window.addEventListener("beforeunload", this.saveForLater)
    let c = localStorage.getItem('cart');
    let e = localStorage.getItem('extras');
    let cs = localStorage.getItem('currentStep');
    if (c) { c = JSON.parse(c); c= c.cart; this.cart = c; }
    if (e) { e = JSON.parse(e); e= e.extras; this.extras = e; }
    if(cs) { this.currentStep = parseInt(cs,10);}
    try{
      let services = await this.$axios.get("/services");
      this.json["services"] = services.data.services;

      let experiences = await this.$axios.get("/experiences");
      this.json["experiences"] = experiences.data.experiences;

      let value = await this.$axios.get("/discount_value")
      this.discountValue = value.data.discount_value

    }catch(e){
      console.log("error bookpage beforeMount: ", e.toString())
    }

  },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
    window.removeEventListener("beforeunload", this.saveForLater);
    this.saveForLater();
  },
}
</script>

<style scoped>
.float-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.extra-space{
  margin-top: 69px;
}
</style>
