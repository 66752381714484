<template>
  <div class="container-fluid m-0 p-0">
    <NavbarTop :page="page" @emitPageChange="catchPageChange" />
    <div class="row m-0 p-0 vh-100">
      <div class="col m-0 p-0  ">
        <RouterView @emitPageChange ="catchPageChange" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return{
      page:0,
    }
  },
  mounted(){
    let cp = localStorage.getItem('page');
    if (cp !== null) this.page = parseInt(cp);
    else localStorage.setItem('page', this.page);
  },
  beforeUnmount(){ localStorage.setItem('page', 'home'); },

  methods:{
    catchPageChange(pageObj){
      let value = pageObj.page
      if (value === 'home') { this.page=0;  this.$router.push('/') }
      else if (value === 'safety') { this.page=1;  this.$router.push('/safety') }
      else if (value === 'where') { this.page=2;  this.$router.push('/where') }
      else if (value === 'contacts') { this.page=3;  this.$router.push('/contacts') }
      else if (value === 'book') { this.page=4;  this.$router.push('/book') }
      localStorage.setItem('page', this.page);

    }
  },
}
</script>

<style scoped>
</style>
