<template>
    <div class="card p-0">
        <div v-if="true">
            <img class="card-img-top img-fluid p-0 m-0" :src="getImageSrc(id)" alt="My Image" />
        </div>

        <div class="card-body">
            <h5 class="card-title text-center" style="color: orange;"> {{ name }}</h5>
            <p v-html="description" class="card-text text-center"></p>
        </div>

        <div class="card-footer">
            <h6>Duration: ~ {{ duration }} h</h6>

            <!--<h6 > Price: {{ price * quantity }}€ or {{ (price * quantity) - (discount * quantity) }}€ with hotel discount code</h6>-->
            <h6 class="mb-4"> Price: {{ price}}€ or {{price  - discount }}€ with hotel discount code</h6>
            <div class="container-fluid m-0 p-0">
                <div class="row mt-2 m-0 p-0">
                    <div class="col-sm-4 col-4 d-flex align-items-center justify-content-start p-0 m-0">
                        <button class="btn btn-dark increment-buttons"
                            @click="removeFromCard" :disabled="quantity == 0">-</button>
                    </div>
                    <div class="col-sm-4  col-4 d-flex align-items-center justify-content-center p-0 m-0">
                        <h4 class="">{{ quantity }}</h4>
                    </div>
                    <div class="col-sm-4  col-4 d-flex align-items-center justify-content-end p-0 m-0">
                        <button class="btn btn-dark increment-buttons" @click="() => {this.quantity += 1; emitAddToCart()}">+</button>
                    </div>
                </div>
                <div class="row mt-2 mb-2 justify-content-center">

                    <!--
                    <div class="col-8-md col-6 ">
                        <button @click="emitAddToCart" class="btn pir-success w-100">Add</button>
                    </div>
                    -->
                    <div class="col-8 " v-if="bookedAmount > 0">
                        <div @click="emitRemoveFromCart" class="btn pir-danger w-100">Remove all</div>
                    </div>

                </div>
                <div v-if="bookedAmount > 0" class="row rounded">
                    <div class="col mb-2 text-center">
                        <span class="" style="font-weight: 600;">{{ bookedAmount }}x {{ name }} in cart</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import images from "../assets/img";

export default {
    props: {
        id: String,
        name: String,
        description: String,
        duration: String,
        price: Number,
        cart: Array,
        discount: Number
    },
    data() {
        return {
            quantity: 0,
            bookedAmount: 0,
        }
    },
    mounted() {
        let v = []
        if (this.cart.length !== 0) v = this.cart.filter(x => x.name_exp === this.name);
        if (v.length !== 0){ 
            this.bookedAmount = v[0].quantity;
            this.quantity = this.bookedAmount;
        }
    },

    methods: {
        removeFromCard(){
            if (this.quantity > 0){ 
                this.quantity -= 1;
                this.emitAddToCart();
            }
            if (this.quantity === 0){
                this.emitRemoveFromCart();
            }
        },

        getImageSrc(imageName) {
            //console.log("CHE SUCC: ", images[imageName], imageName, images)
            return images[imageName];
        },
        emitAddToCart() {
            if (this.quantity > 0) {
                this.$emit('addedToCart', { "name_exp": this.name, "quantity": this.quantity, "price": this.price, "id": this.id });
                this.bookedAmount = this.quantity;
            }
        },
        emitRemoveFromCart() {
            this.$emit('removedFromCart', { "name_exp": this.name });
            this.bookedAmount = 0;
            this.quantity = 0;
        },
    }
}
</script>

<style scoped>
</style>
