<template>
  <div class="container-fluid p-1">
    <div class="row">
      <div class="col">
        <div class="container-fluid p-0 m-0 table-responsive ">
          <table class="table lc">
            <thead>
              <tr>
                <th class="col-6 pir-color">Name</th>
                <th class="col-2 pir-color">Quantity</th>
                <th class="col-2 pir-color">Price</th>
                <th class="col-2 pir-color">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cart" :key="item.name_exp">
                <td>{{ item.name_exp }}  <i  @click="emitRemoveItem('SERVICE', item.name_exp)" class="fa-regular fa-trash-can my-trash-icon my-btn-del ms-2"></i></td>
                <td>
                  <div class="btn-group btn-group-sm lc" role="group">

                    <button :disabled="item.quantity==1"
                      @click="() => { if (item.quantity - 1 > 0) { item.quantity -= 1 }}"
                      type="button" class="btn btn-outline-light">-</button>

                    <button class="btn lc"> {{ item.quantity }}</button>

                    <button @click="() => item.quantity += 1" type="button" class="btn btn-outline-light">+</button>

                  </div>
                </td>
                <td>{{ item.price }}</td>
                <td>{{ item.quantity * item.price }}€</td>
                
              </tr>
              <tr v-for="item in extra" :key="item.name_service">
                <td>{{ item.name_service }}  <i  @click="emitRemoveItem('EXTRA', item.name_service)" class="fa-regular fa-trash-can my-trash-icon my-btn-del ms-2"></i></td>
                <td>
                  <div class="btn-group btn-group-sm lc" role="group">
                    <button @click="() => { if (item.quantity - 1 > 0) { item.quantity -= 1 }}" type="button" class="btn btn-outline-light">-</button>
                    <button class="btn lc"> {{ item.quantity }}</button>
                    <button @click="() => item.quantity += 1" type="button" class="btn btn-outline-light">+</button>
                  </div>
                </td>
                <td>{{ item.price }}</td>
                <td>{{ item.quantity * item.price }}€</td>
                
                    
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th> </th>
                <th>Total</th>
                <th v-if="discountApplied" class=""> <span class="me-2" style="text-decoration: line-through;">{{
                  total + calculateDiscount() }}€</span>→ {{ total }}€</th>
                <th v-if="!discountApplied">{{ total }}€</th>
                <th></th>
              </tr>

              <!-- -->
              <tr v-if="discountApplied">
                <!--<th class="disc-app-color"> Discount applied successfully!</th> -->
                <th></th>
                <th></th>
                <th>You saved:</th>
                <th>{{ calculateDiscount() }}€</th>
                <th></th>
              </tr>
              
            </tfoot>
          </table>


        </div>
      </div>
    </div>


    <div class="row justify-content-center text-center mt-3">
      <div class="col-12">
        <h6>Have you got a discount code?</h6> 
      </div>
      <div class="col-12 d-flex justify-content-center mt-2 mb-2">
        <input v-model="discountCode" type="text" class="form-control" maxlength="5" style="max-width: 20ch;"
          placeholder="Discount code" />
        <button :disabled="discountCode.length<5" @click="applyDiscount" type="button" class="btn btn-outline-success btn-sm">Apply</button>
      </div>
      <div v-if="discountApplied" class="col-12">
        <span style="color: green; font-weight: 550;">Discount code {{ discountCode }} applied successfully!</span>
      </div>
      <div v-if="invalidCode" class="col-12">
        <span style="color: red;">Invalid discount code, retry!</span>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      discountCode: "",
      discountApplied: false,
      invalidCode: false
    };
  },
  props: {
    cart: Array,
    extra: Array,
    discount: Number
  },
  methods: {
    async applyDiscount() {
      //Check if code is actually working from backend
      try {
				let response = await this.$axios.post("/discount_verifier",
        {'discount_code':this.discountCode}
        );
        if (response.status === 200){
          this.invalidCode = false;
        }
        
        if (!this.discountApplied) {
          this.discountApplied = !this.discountApplied;
          this.$emit("discountApplied",this.discountCode)
        }
				
			} catch (e) {
        if (e.response.status === 404){ this.invalidCode=true; this.discountCode = '' }
			}

    },
    calculateDiscount() {
      if (!this.discountApplied) return 0;
      return this.discount * this.cart.reduce((a, x) => a + x.quantity, 0);
    },
    emitRemoveItem(type, name_item) { this.$emit("emitRemoveItem", { type_item: type, name_item: name_item }) }

  },
  computed: {
    total() {
      return this.cart.reduce(
        (accumulator, item) => accumulator + item.quantity * item.price,
        0
      ) + this.extra.reduce(
        (accumulator, item) => accumulator + item.quantity * item.price,
        0
      ) - this.calculateDiscount();

    },
  },
};
</script>
  
<style>
/* Add any additional styling here */
.lc {
  color: aliceblue;
}

.lc:hover {
  color: aliceblue;
}

.disc-app-color {
  color: green;
}

.my-btn-del{
    border: none;
}
.my-btn-del:hover{
    border: none;
    color: red;
    transform: scale(1.1);
    cursor: pointer;
}
</style>
  