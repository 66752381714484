// src/assets/img.js

const images = {
    "PARA_STD": require("./standard_tandem.jpeg"),
    "PARA_STD_SYNC": require("./sync_tandem.jpeg"),
    "PARA_ACRO": require("./acro_tandem.png"),
    "PARA_XC": require("./xc_tandem.png"),
    "Norma": require("./norma.jpeg"),
    "Tivoli": require("./tivoli.jpeg"),
    "Cassino": require("./cassino.jpg"),
    "Poggio Bustone": require("./poggio_bustone.jpg"),
    "Trevignano": require("./trevignano.jpg"),
  };
  
  export default images;
  