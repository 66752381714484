<template>
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center mt-5 pt-4 mb-5">

    <div class="row mb-2">
      <div class="col">
        <h1 class="pir-color">Is paragliding safe?</h1>
      </div>
    </div>

    <div class="row justify-content-center mb-4">
      <div class="col text-center">
        When it comes to paragliding, choosing the right pilot is of paramount importance for your safety. <br><br>
        The level of safety in paragliding largely depends on the expertise and responsibility of the pilot you entrust your adventure to.
        At Paragliding Rome, we prioritize your safety above all else. Our team consists of experienced pilots certified by the Italian Aeronautical Club,
        who actively participate in high-level competitions. 
        Their knowledge and experience ensure the use of cutting-edge, highly reliable equipment with advanced safety features.
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col text-center">
        To us, safety is non-negotiable. We meticulously monitor weather conditions, and if necessary, we do not hesitate to cancel a flight before takeoff. 
        Adequate emergency parachute systems are essential, and our pilots carefully consider optimal weather conditions before taking flight.
        <br><br>
        Rest assured, with our expert pilots, up-to-date equipment, and adherence to safety protocols, you can trust that you are in good hands. 
        Your safety is our utmost priority, ensuring you have a memorable and secure paragliding experience. So, let us take you on an unforgettable journey through the skies, 
        knowing that your safety is at the heart of everything we do.
      </div>
    </div>
    
  </div>
</template>