import { createApp } from 'vue'
import { RouterView } from 'vue-router'
import App from './App.vue'
import router from './router'
import axios from './services/axios.js';
import ExperienceCard from './components/ExperienceCard'
import ExtraService from './components/ExtraService'
import BookingForm from './components/BookingForm'
import BookingCart from './components/BookingCart'
import SuccBooking from './components/SuccBooking'
import NavbarTop from './components/NavbarTop'
import PlaceCard from './components/PlaceCard'
import './style/style.css'

const app = createApp(App)

app.config.globalProperties.$axios = axios;

app.component("ExperienceCard",ExperienceCard)
app.component("ExtraService",ExtraService)
app.component("BookingForm",BookingForm)
app.component("BookingCart",BookingCart)
app.component("SuccBooking",SuccBooking)
app.component("NavbarTop",NavbarTop)
app.component("PlaceCard",PlaceCard)

app.use(router)
app.use(RouterView)
app.mount('#app')


